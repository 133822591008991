// Contacts stylesheet
@import 'src/site-variables.scss';

.confirm-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.confirm-number__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: 400px;
    margin-top: 50px;

    div {
      text-align: center;
    }
  }

  button {
    margin: 30px;
    background-color: white;
    color: $dark-grey;
  }
}

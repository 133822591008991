// Paragraph stylesheet
@import 'src/site-variables.scss';

.paragraph {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}

.paragraph__wrapper {
  max-width: 1000px;
}

// Site Colours
$safety-orange-blaze-orange: #ff6600ff;
$davys-grey: #515052ff;
$ash-gray: #b3bfb8ff;
$mint-cream: #f0f7f4ff;
$green-blue-crayola: #008dd5ff;
$white: #ffffff;
$black: #000000;

// Site Colour Variables
$primary-colour: $safety-orange-blaze-orange;
$secondary-colour: $green-blue-crayola;
$dark-grey: $davys-grey;
$medium-grey: $ash-gray;
$light-grey: $mint-cream;

// Font Weights - these need clarifying
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// Define standard margins

// Fonts
$title-font-family: 'Barlow', sans-serif; // 'Barlow Semi Condensed', sans-serif;
$body-font-family: 'Barlow', sans-serif;

// Font size
$title-h1-size: 32px;
$title-h2-size: 26px;
$title-h3-size: 20px;

// App stylesheet
@import 'src/site-variables.scss';

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 2;
}

// Registration stylesheet
@import 'src/site-variables.scss';

.register {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.register__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .PhoneInput, input.surname {
    margin-bottom: 30px;
  }
}

// Button stylesheet
@import 'src/site-variables.scss';

.button {
  cursor: pointer;
  background-color: $secondary-colour;
  border: 2px solid $medium-grey;
  min-width: 130px;
  min-height: 32px;
  border-radius: 3px;
  color: $white;

  &:hover {
    border: 2px solid $secondary-colour;
  }

  &:disabled {
    background-color: $medium-grey;
    cursor:auto;

    &:hover {
      border: none;
    }
  }
}
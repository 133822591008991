// RegisterSuccess stylesheet
@import 'src/site-variables.scss';

.registerSuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .paragraph {
    text-align: center;
  }

  .button {
    width: 100px;
    margin: 20px auto;
  }
}

// Input stylesheet
@import 'src/site-variables.scss';

// All Inputs Styling

.input input {
  width: 300px;
  border-radius: 3px;
  padding: 10px 5px;
  border: 2px solid $medium-grey;
  margin-bottom: 15px;
}


// Phone Input Styling

input.PhoneInputInput {
  width: 250px;
  margin-left: 7px;
}

.PhoneInputCountry {
  height: 40px;
}

// Contacts stylesheet
@import 'src/site-variables.scss';

.about {
  .about_header {
    position: relative;
    text-align: center;
    margin-bottom: -4px;

    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    
    .image {
      object-fit: cover;
      width: 100%;
    }
  }

  .about_content {
    .about_section {
      display: flex;
      flex-direction: row;
      margin: 30px auto;
      max-width: 1000px;
      gap: 30px;
  
      .image {
        order: 0;
        object-fit: cover;
        max-width: 250px;
        aspect-ratio: 1;
        border-radius: 50%;
      }
  
      .paragraph {
        order: 1;
        margin: auto;
      }
    }
  
    .about_section:nth-of-type(2n) {
      .image {
        order: 2
      }
    }
  }
}

// Title stylesheet
@import 'src/site-variables.scss';

.title {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 20px;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 20px;
  font-weight: $semi-bold;
  font-family: $title-font-family;
}

.title-h1 {
  font-size: $title-h1-size;
}

.title-h2 {
 font-size: $title-h2-size;
}

.title-h3 {
  font-size: $title-h3-size;
}

@import 'src/site-variables.scss';

.nav {
  background-color: $primary-colour;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.nav-items {
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  gap: 20px;
  padding: 10px;
}

.nav-item {
  color: $black;
  text-decoration: none;
  font-weight: 600;
  min-width: 100px;
  min-height: 30px;
  border-radius: 3px;
  border: 1px solid black;
  text-align: center;
  font-size: 18px;
}

// Contacts stylesheet
@import 'src/site-variables.scss';

.contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.contacts__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

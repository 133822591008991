// Login stylesheet
@import 'src/site-variables.scss';

.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

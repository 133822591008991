// Home stylesheet
@import 'src/site-variables.scss';

.home {
  .home-header {
    position: relative;
    text-align: center;
    margin-bottom: -4px;
  
    .title {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
  
      &-h1 {
        top: 35%;
        font-size: 40px;
      }
  
      &-h2 {
        top: 55%;
      }
    }

    .home_header-logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .home_header-background {
      object-fit: cover;
      width: 100%;
    }
  }

  .paragraph {
    text-align: center;
  }

  .register-cta {
    height: 60px;
    width: 250px;
    font-size: $title-h2-size;
    font-family: $title-font-family;
    font-weight: $bold;
  }

  .home_step-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0 50px;

    ol {
      max-width: 1000px;

      li::marker{
        font-size: $title-h2-size;
        font-family: $title-font-family;
        font-weight: $bold;
      }
    }
  }
}

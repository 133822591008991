// Bar stylesheet
@import 'src/site-variables.scss';

.bar {
  background: $primary-colour;
  color: $light-grey;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
}

.bar__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
